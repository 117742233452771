import SEO from "@/components/SEO";
import * as QueryString from "query-string";
import React, { useEffect } from "react";

function index({ location }) {
  useEffect(() => {
    const search = QueryString.parse(location.search);
    const { youtube } = search;
    console.log(youtube);
    if (!youtube) {
      window.location.replace(`/`);
    } else {
      window.location.replace(`https://youtu.be/${youtube}`);
    }
  }, []);

  return (
    <>
      <SEO />
      redirect page
    </>
  );
}

export default index;
